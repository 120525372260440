<template>
    <div class="index">
        <img src="../assets/logo-black.png"/>
    </div>
</template>

<script>

export default {
    name: 'Index',
    components: {}
}

</script>

<style>
img{

    width: 120px;
    height: 120px;
    margin-top: 60px;
    /* margin-left: -90px; */
}
/* .index{
    background-color: aqua;
} */
</style>