<template>
<div>
  <AppBar title="Index of 1B.MK" style="position:flex"/>
</div>
</template>

<script>
import { AppBar } from '@varlet/ui'
export default {
    name: "TopBar",
    components:{
        AppBar
    }
}
</script>

<style>
</style>